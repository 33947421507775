<template>
    <v-container fluid>
        <LoadingBar/>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Job Order Create</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.name"
                        label="Name*"
                        placeholder="Name"
                        hint="Minimum of 3 characters required"
                        persistent-hint
                        validate-on="blur"
                        :rules="[
                            rules.required, 
                            rules.min(3), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.work_order_number"
                        label="Job Order Number*"
                        placeholder="Job Order Number"
                        validate-on="blur"
                        @input="numbersOnly($event, 'work_order_number');"
                        @blur="convertToNumber('work_order_number')"
                        :rules="[
                            rules.required,
                            rules.alphanumeric,
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.work_order_status"
                        :items="workOrderStatuses"
                        item-title="title"
                        item-value="value"
                        label="Status"
                        placeholder="Status"
                        @blur="convertEmptyToNull('work_order_status')"
                    >
                    </v-select>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-select
                        v-model="entityCore.contract_uid"
                        :items="contractsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Contract*"
                        placeholder="Contract"
                        @blur="convertEmptyToNull('contract_uid')"
                        @focus="fetchList('contract', 'owner-' + entityAncestry.owner)"
                        :loading="contractsList.loading"
                        :disabled="contractsList.disabled"
                        :hint="contractsList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.stage"
                        :items="workOrderStages"
                        item-title="title"
                        item-value="value"
                        label="Stage"
                        placeholder="Stage"
                        @blur="convertEmptyToNull('stage')"
                    >
                    </v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.location_info"
                        label="Location Info"
                        placeholder="Location info"
                        @blur="convertEmptyToNull('location_info')"
                        :rules="[
                            rules.max(264)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-textarea
                        label="Scope of Work*"
                        v-model.lazy="entityCore.scope_of_work"
                        variant="filled"
                        auto-grow
                        :rules="[
                            rules.required,
                            rules.max(2000)
                        ]"
                    ></v-textarea>
                </v-col>
            </v-row>

            <v-row><v-col><v-divider></v-divider></v-col></v-row>

            <v-row>
                <v-col>
                    <h3>Owner Project Managers</h3>
                </v-col>
            </v-row>

        <v-row>
                <v-col>
                    <v-select
                        v-model="entityCore.owner_project_manager_primary_uid"
                        :items="ownerContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Primary Owner Project Manager*"
                        placeholder="Primary Owner Project Manager"
                        @blur="convertEmptyToNull('owner_project_manager_primary_uid')"
                        @focus="fetchList('owner_contact', 'owner-' + entityAncestry.owner)"
                        :loading="ownerContactsList.loading"
                        :disabled="ownerContactsList.disabled"
                        :hint="ownerContactsList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :disabled="alreadySelectedOwnerContacts.includes(item.value)"></v-list-item>
                        </template>
                    </v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.owner_project_manager_2_uid"
                        :items="ownerContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Secondary Owner Project Manager"
                        placeholder="Secondary Owner Project Manager"
                        @blur="convertEmptyToNull('owner_project_manager_2_uid')"
                        :loading="ownerContactsList.loading"
                        :hint="ownerContactsList.hint"
                        :disabled="!entityCore.owner_project_manager_primary_uid"
                        persistent-hint
                        clearable
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :disabled="alreadySelectedOwnerContacts.includes(item.value)"></v-list-item>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="6">
                    <v-select
                        v-model="entityCore.owner_project_manager_3_uid"
                        :items="ownerContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Tertiary Owner Project Manager"
                        placeholder="Tertiary Owner Project Manager"
                        @blur="convertEmptyToNull('owner_project_manager_3_uid')"
                        :loading="ownerContactsList.loading"
                        :hint="ownerContactsList.hint"
                        :disabled="!entityCore.owner_project_manager_primary_uid"
                        persistent-hint
                        clearable
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :disabled="alreadySelectedOwnerContacts.includes(item.value)"></v-list-item>
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <v-row><v-col><v-divider></v-divider></v-col></v-row>
            
            <v-row>
                <v-col>
                    <h3>Contractor Project Managers</h3>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-select
                        v-model="entityCore.contractor_project_manager_primary_uid"
                        :items="contractorContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Primary Contract Project Manager*"
                        placeholder="Primary Contract Project Manager"
                        @blur="convertEmptyToNull('contractor_project_manager_primary_uid')"
                        @focus="fetchList('contractor_contact', 'contractor-' + contractorDoc._id)"
                        :loading="contractorContactsList.loading"
                        :disabled="contractorContactsList.disabled"
                        :hint="contractorContactsList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :disabled="alreadySelectedContractorContacts.includes(item.value)"></v-list-item>
                        </template>
                        <template v-slot:message="{message}">
                            <div class="input-hint" v-html="message"></div>
                        </template>
                    </v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.contractor_project_manager_2_uid"
                        :items="contractorContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Secondary Contractor Project Manager"
                        placeholder="Secondary Contractor Project Manager"
                        @blur="convertEmptyToNull('contractor_project_manager_2_uid')"
                        :loading="contractorContactsList.loading"
                        :hint="contractorContactsList.hint"
                        :disabled="!entityCore.contractor_project_manager_primary_uid"
                        persistent-hint
                        clearable
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :disabled="alreadySelectedContractorContacts.includes(item.value)"></v-list-item>
                        </template>
                        <template v-slot:message="{message}">
                            <div class="input-hint" v-html="message"></div>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="6">
                    <v-select
                        v-model="entityCore.contractor_project_manager_3_uid"
                        :items="contractorContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Tertiary Contractor Project Manager"
                        placeholder="Tertiary Contractor Project Manager"
                        @blur="convertEmptyToNull('contractor_project_manager_3_uid')"
                        :loading="contractorContactsList.loading"
                        :hint="contractorContactsList.hint"
                        :disabled="!entityCore.contractor_project_manager_primary_uid"
                        persistent-hint
                        clearable
                    >
                        <template v-slot:item="{ props, item }">
                            <v-list-item v-bind="props" :disabled="alreadySelectedContractorContacts.includes(item.value)"></v-list-item>
                        </template>
                        <template v-slot:message="{message}">
                            <div class="input-hint" v-html="message"></div>
                        </template>
                    </v-select>
                </v-col>
            </v-row>

            <v-row><v-col><v-divider></v-divider></v-col></v-row>

            <v-row>
                <v-col>
                    <h3>Approvers</h3>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-select
                        v-model="entityCore.inspector_uid"
                        :items="ownerContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Inspector*"
                        placeholder="Inspector"
                        @blur="convertEmptyToNull('inspector_uid')"
                       @focus="fetchList('owner_contact', 'owner-' + entityAncestry.owner)"
                        :loading="ownerContactsList.loading"
                        :disabled="ownerContactsList.disabled"
                        :hint="ownerContactsList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.rfp_signing_authority_uid"
                        :items="ownerContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="RFP Signing Authority*"
                        placeholder="RFP Signing Authority"
                        @blur="convertEmptyToNull('rfp_signing_authority_uid')"
                       @focus="fetchList('owner_contact', 'owner-' + entityAncestry.owner)"
                        :loading="ownerContactsList.loading"
                        :disabled="ownerContactsList.disabled"
                        :hint="ownerContactsList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-select
                        v-model="entityCore.ntp_signing_authority_uid"
                        :items="ownerContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="NTP Signing Authority*"
                        placeholder="NTP Signing Authority"
                        @blur="convertEmptyToNull('ntp_signing_authority_uid')"
                       @focus="fetchList('owner_contact', 'owner-' + entityAncestry.owner)"
                        :loading="ownerContactsList.loading"
                        :disabled="ownerContactsList.disabled"
                        :hint="ownerContactsList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.woa_signing_authority_uid"
                        :items="clientContactsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="JOA Signing Authority*"
                        placeholder="JOA Signing Authority"
                        @blur="convertEmptyToNull('woa_signing_authority_uid')"
                        @focus="fetchList('client_contact', 'client-' + clientDoc._id)"
                        :loading="clientContactsList.loading"
                        :disabled="clientContactsList.disabled"
                        :hint="clientContactsList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
            </v-row>

            <v-row><v-col><v-divider></v-divider></v-col></v-row>

            <v-row>
                <v-col>
                    <h3>Calculated Dates</h3>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.construction_start_date"
                        label="Construction Start Date*"
                        @blur="convertToDateObject($event, 'construction_start_date')"
                        validate-on="blur"
                        :rules="[
                            rules.required,
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.time_extension"
                        label="Time Extension*"
                        placeholder="Time Extension"
                        validate-on="blur"
                        @input="numbersOnly($event, 'time_extension');"
                        @blur="convertToNumber('time_extension')"
                        persistent-hint
                        hint="Calendar days"
                        :rules="[
                            rules.required,
                            rules.numbersOnly,
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.start_to_substantial_completion_days"
                        label="Construction Start to Substantial Completion in Days*"
                        placeholder="Construction Start to Substantial Completion in Days"
                        validate-on="blur"
                        @input="numbersOnly($event, 'start_to_substantial_completion_days');"
                        @blur="convertToNumber('start_to_substantial_completion_days')"
                        persistent-hint
                        :hint="calculatedDateData.startToSubstantialHint"
                        :rules="[
                            rules.required,
                            rules.numbersOnly,
                        ]"
                    >
                        <template v-slot:message="{message}">
                            <div class="input-hint" v-html="message"></div>
                        </template>
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.substantial_to_completion_days"
                        label="Substantial to final Completion in Days*"
                        placeholder="Substantial to final Completion in Days"
                        validate-on="blur"
                        @input="numbersOnly($event, 'substantial_to_completion_days');"
                        @blur="convertToNumber('substantial_to_completion_days')"
                        persistent-hint
                        :hint="calculatedDateData.substantialToFinalHint"
                        :rules="[
                            rules.required,
                            rules.numbersOnly,
                        ]"
                    >
                        <template v-slot:message="{message}">
                            <div class="input-hint" v-html="message"></div>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row><v-col><v-divider></v-divider></v-col></v-row>

            <v-row>
                <v-col>
                    <h3>Administrative Dates</h3>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.site_walk_through_date"
                        label="Site Walk Through Date"
                        @blur="convertToDateObject($event, 'site_walk_through_date')"
                        validate-on="blur"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.rfp_issue_date"
                        label="RFP Issue Date"
                        @blur="convertToDateObject($event, 'rfp_issue_date')"
                        validate-on="blur"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.proposal_due_date"
                        label="Proposal Due Date*"
                        @blur="convertToDateObject($event, 'proposal_due_date')"
                        validate-on="blur"
                        :rules="[
                            rules.required,
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.accepted_proposal_date"
                        label="Accepted Proposal Date"
                        @blur="convertToDateObject($event, 'accepted_proposal_date')"
                        validate-on="blur"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.work_order_authorization_date"
                        label="Job Order Authorization Date"
                        @blur="convertToDateObject($event, 'work_order_authorization_date')"
                        validate-on="blur"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.ntp_issued_date"
                        label="NTP Issued Date"
                        @blur="convertToDateObject($event, 'ntp_issued_date')"
                        validate-on="blur"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.eeo_clearance_date"
                        label="EEO Clearance Date"
                        @blur="convertToDateObject($event, 'eeo_clearance_date')"
                        validate-on="blur"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.notice_of_completion_file_date"
                        label="Notice of Completion File Date"
                        @blur="convertToDateObject($event, 'notice_of_completion_file_date')"
                        validate-on="blur"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
            </v-row>

            <v-row><v-col><v-divider></v-divider></v-col></v-row>

            <v-row>
                <v-col>
                    <h3>Fees & Damages</h3>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.liquidated_damages_to_district"
                        label="Liquidated Damages to District*"
                        placeholder="Liquidated Damages to District"
                        @blur="convertToNumber('liquidated_damages_to_district')"
                        @input="numbersAndDecimalsOnly($event, 'liquidated_damages_to_district');"
                        validate-on="blur"
                        hint="Per calendar day"
                        persistent-hint
                        prepend-inner-icon="mdi-currency-usd"
                        :rules="[
                            rules.required,
                            rules.numberWithDecimal
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.liquidated_damages_to_contractor"
                        label="Liquidated Damages to Contractor*"
                        placeholder="Liquidated Damages to Contractor"
                        @blur="convertToNumber('liquidated_damages_to_contractor')"
                        @input="numbersAndDecimalsOnly($event, 'liquidated_damages_to_contractor');"
                        validate-on="blur"
                        hint="Per calendar day"
                        persistent-hint
                        prepend-inner-icon="mdi-currency-usd"
                        :rules="[
                            rules.required,
                            rules.numberWithDecimal
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        v-model="entityCore.jamb_fee_percentage"
                        label="JAMB Fee Percentage*"
                        placeholder="JAMB Fee Percentage"
                        @blur="convertToNumber('jamb_fee_percentage')"
                        @input="numbersAndDecimalsOnly($event, 'jamb_fee_percentage');"
                        validate-on="blur"
                        append-inner-icon="mdi-percent"
                        :rules="[
                            rules.required,
                            rules.numberWithDecimal
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            
            

            <FormCreateFooter 
                :form="form"
                :entityCore="{ ...entityCore, ...calculatedDateData.entityData }"
                :current="current"
                :parent="parent" 
            />
        </v-form>
    </v-container>
</template>
<script setup>
import { useStore } from 'vuex';
import { ref, reactive, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import rules from "@/assets/modules/rules.js";
import workOrderStatuses from "@/assets/selects/project-work-order-status.json";
import workOrderStages from "@/assets/selects/project-work-order-stage.json";
import LoadingBar from "@/components/interface/LoadingBar.vue";
import FormCreateFooter from "@/components/interface/FormCreateFooter.vue";
import { useFieldValidation, useEntityAncestry, useFetchSingle, useFetchList, useNameById } from "@/assets/modules/composables.js";
import useCalculatedDateData from "@/assets/modules/workOrderCalculatedDateData.js";

const store = useStore();
const entityCore = ref({});
const route = useRoute();
const form = ref();
const alreadyFetchedData = [];
const isLoading = reactive({});
const { convertEmptyToNull, numbersOnly, numbersAndDecimalsOnly, convertToNumber, convertToDateObject } = useFieldValidation(entityCore);
const { entityAncestry } = useEntityAncestry();
const { fetchSingle } = useFetchSingle(isLoading, alreadyFetchedData);
const { fetchList } = useFetchList(isLoading, alreadyFetchedData);
const { nameById } = useNameById();
const { calculatedDateData } = useCalculatedDateData(entityCore);
//const returnObject = { ...entityCore, ...calculatedDateData.value.entityData };


const current = {
    type: "work_order"
};
const parent = {
    id: route.params.parent_id,
    type: "project"
};

// Make an array to reference which owner contacts IDs have already been selected
const alreadySelectedOwnerContacts = computed(() => {
    return [
        entityCore.value.owner_project_manager_primary_uid, 
        entityCore.value.owner_project_manager_2_uid, 
        entityCore.value.owner_project_manager_3_uid
    ];
})
const alreadySelectedContractorContacts = computed(() => {
    return [
    entityCore.value.contractor_project_manager_primary_uid,
    entityCore.value.contractor_project_manager_2_uid,
    entityCore.value.contractor_project_manager_3_uid
    ];
})




// Need this to get the client document
const projectDoc = computed(() => {
    return store.getters.getEntityDocument(entityAncestry.value?.project);
})
// Need this for populating the client contacts list
const clientDoc = computed(() => {
    return store.getters.getEntityDocument(projectDoc.value?.core?.client_uid);
})
const contractDoc = computed(() => {
    return store.getters.getEntityDocument(entityCore.value.contract_uid);
})
const contractorDoc = computed(() => {
    return store.getters.getEntityDocument(contractDoc.value?.core?.contractor_uid);
})
// Get the client document when the project document is loaded
// This is needed for the JOA Signing Authority field
watch(
    () => projectDoc,
    async () => {
        if(!alreadyFetchedData.includes(projectDoc.value?.core?.client_uid)){
            fetchSingle("client", projectDoc.value?.core?.client_uid);
        }
    },
    { deep: true }
)


const contractsList = computed(() => {
    const items = store.getters.getChildList("owner." + entityAncestry.value.owner + ".", "contract");
    const hint = nameById(entityAncestry.value.owner, null) ? `List of contracts for ${nameById(entityAncestry.value.owner, null)}` : 'List of contracts'
    const disabled = !entityAncestry.value.owner;
    const loading = isLoading['contract_owner-' + entityAncestry.value.owner];
    return {
        items: items,
        hint: hint,
        disabled: disabled,
        loading: loading,
    };
})
const ownerContactsList = computed(() => {
    const items = store.getters.getChildList("owner." + entityAncestry.value.owner + ".", "owner_contact");
    const hint = nameById(entityAncestry.value.owner, null) ? `List of owner contacts from ${nameById(entityAncestry.value.owner, null)}` : 'List of owner contacts'
    const disabled = !entityAncestry.value.owner;
    const loading = isLoading['owner_contact_owner-' + entityAncestry.value.owner];
    return {
        items: items,
        hint: hint,
        disabled: disabled,
        loading: loading,
    };
})
const clientContactsList = computed(() => {
    const items = store.getters.getChildList("client." + clientDoc.value?._id + ".", "client_contact");
    const hint = clientDoc.value?._name ? `List of client contacts for ${clientDoc.value?._name} via ${projectDoc.value?._name}` : 'List of client contacts'
    const disabled = !clientDoc.value?._name;
    const loading = isLoading['client_contact_client-' + clientDoc.value?._id];
    return {
        items: items,
        hint: hint,
        disabled: disabled,
        loading: loading,
    };
})
const contractorContactsList = computed(() => {
    const items = store.getters.getChildList("contractor."+contractDoc.value?.core?.contractor_uid+".", "contractor_contact");
    const hint = contractorDoc.value ? `List of contractor contacts from ${contractorDoc.value?._name}` : 'List of contractor contacts'
    const disabled = !contractorDoc.value;
    const loading = isLoading['contractor_contact_contractor-' + contractorDoc.value?._id];
    return {
        items: items,
        hint: hint,
        disabled: disabled,
        loading: loading,
    };
})


watch(
    () => entityCore.value.contract_uid,
    async (newVal) => {
        const contractId = newVal;
        entityCore.value.contractor_project_manager_primary_uid = null;
        entityCore.value.contractor_project_manager_2_uid = null;
        entityCore.value.contractor_project_manager_3_uid = null;
        if(!alreadyFetchedData.includes(contractId)){
            fetchSingle("contract", contractId);
            const contractorId = store.getters.getEntityDocument(contractId)?.core?.contractor_uid;
            if(contractorId && !alreadyFetchedData.includes(contractorId)){
                fetchSingle("contractor", contractorId);
            }
        }
    },
    { deep: true }
)
</script>