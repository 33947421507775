const awsmobile = {
    "aws_project_region": "us-west-1",
    // "aws_cognito_identity_pool_id": "us-west-1:edc3b742-3e0b-4e87-a0c6-8b6eddd4767a",
    // "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": process.env.VUE_APP_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
    // "oauth": {},
    // "aws_cognito_username_attributes": [
    //     "EMAIL"
    // ],
    // "aws_cognito_social_providers": [],
    // "aws_cognito_signup_attributes": [
    //     "EMAIL"
    // ],
    // "aws_cognito_mfa_configuration": "ON",
    // "aws_cognito_mfa_types": [
    //     "EMAIL"
    // ],
    // "aws_cognito_password_protection_settings": {
    //     "passwordPolicyMinLength": 8,
    //     "passwordPolicyCharacters": []
    // },
    // "aws_cognito_verification_mechanisms": [
    //     "EMAIL"
    // ],
    // "aws_content_delivery_bucket": "uth-20240322212030-hostingbucket-dev",
    // "aws_content_delivery_bucket_region": "us-west-1",
    // "aws_content_delivery_url": "https://df3gy3e39si0k.cloudfront.net"
};


export default awsmobile;
