<template>
    <v-container fluid>
        <LoadingBar/>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col>
                    <h1>Update Contract</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.name"
                        label="Name*"
                        placeholder="Name"
                        hint="Minimum of 3 characters required"
                        persistent-hint
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.required, 
                            rules.min(3), 
                            rules.max(64)
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                        v-model="entityCore.number"
                        label="Contract Number*"
                        placeholder="Contract Number"
                        validate-on="blur"
                        @blur="convertEmptyToNull('number')"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.required,
                            rules.min(1),
                            rules.max(64),
                        ]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="entityCore.value"
                        label="Contract Value"
                        placeholder="Contract Value"
                        validate-on="blur"
                        @input="numbersAndDecimalsOnly($event, 'value');"
                        @blur="convertToNumber('value')"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.numberWithDecimal
                        ]"
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-select
                        v-model="entityCore.type"
                        :items="contractTypeList"
                        item-title="title"
                        item-value="value"
                        label="Contract Type"
                        placeholder="Contract Type"
                        @blur="convertEmptyToNull('type')"
                        :disabled="!isCurrentEntityLoaded"
                    >
                    </v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="entityCore.procurement_type"
                        :items="contractProcurementTypeList"
                        item-title="title"
                        item-value="value"
                        label="Contract Procurement Type"
                        placeholder="Contract Procurement Type"
                        @blur="convertEmptyToNull('procurement_type')"
                        :disabled="!isCurrentEntityLoaded"
                    >
                    </v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="6">
                    <v-select
                        v-model="entityCore.contractor_uid"
                        :items="contractorsList.items"
                        item-title="_name"
                        item-value="_id"
                        label="Contractor*"
                        placeholder="Contractor"
                        @focus="fetchList('contractor', 'all')"
                        :loading="contractorsList.loading"
                        :disabled="contractorsList.disabled"
                        :hint="contractorsList.hint"
                        persistent-hint
                        :rules="[
                            rules.required,
                        ]"
                    >
                    </v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.start_date"
                        label="Start Date"
                        @blur="convertToDateObject($event, 'start_date')"
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.end_date"
                        label="End Date"
                        @blur="convertToDateObject($event, 'end_date')"
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.award_date"
                        label="Award Date"
                        @blur="convertToDateObject($event, 'award_date')"
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
                <v-col>
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.final_acceptance_date"
                        label="Final Acceptance Date"
                        @blur="convertToDateObject($event, 'final_acceptance_date')"
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="6">
                    <v-date-input
                        prepend-icon=""
                        prepend-inner-icon="$calendar"
                        v-model.lazy="entityCore.notice_of_completion_date"
                        label="Notice of Completion Date"
                        @blur="convertToDateObject($event, 'notice_of_completion_date')"
                        validate-on="blur"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.date,
                        ]"
                    ></v-date-input>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-textarea
                        label="Notes"
                        v-model.lazy="entityCore.notes"
                        variant="filled"
                        auto-grow
                        @blur="convertEmptyToNull('notes')"
                        :disabled="!isCurrentEntityLoaded"
                        :rules="[
                            rules.max(2000)
                        ]"
                    ></v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <h3>Adjustment Factors</h3>
                </v-col>
                <v-col class="d-flex ga-4 justify-end">
                    <v-btn color="primary" variant="outlined" @click="openAdjustmentFactorDialog()">
                        Add
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="adjustmentFactorsListHeaders"
                        :items="entityCore.adjustment_factors"
                        item-key="type"
                        :items-per-page="100"
                        :hide-default-footer="true"
                        disable-sort
                        >
                        <template v-slot:[`item.value`]="{ value }">
                            {{ value.toFixed(4) }}
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col>
                    <h3>Options</h3>
                </v-col>
                <v-col class="d-flex ga-4 justify-end">
                    <v-btn color="primary" variant="outlined" @click="openOptionDialog()">
                        Add
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="optionsListHeaders"
                        :items="entityCore.options"
                        item-key="type"
                        :items-per-page="100"
                        :hide-default-footer="true"
                        disable-sort
                        >
                    </v-data-table>
                </v-col>
            </v-row>

            <v-row v-if="entityCore.associated_unit_price_books">
                <v-col>
                    <h3>Associated Unit Price Books</h3>
                    <v-checkbox
                        v-for="upb in unitPriceBooksList"
                        :key="upb._id"
                        v-model="entityCore.associated_unit_price_books"
                        :label="upb._name"
                        :value="upb._id"
                        density="compact"
                        hide-details="true"
                    ></v-checkbox>
                </v-col>
            </v-row>

            <FormUpdateFooter 
                :form="form"
                :entityCore="entityCore"
                :current="current"
            />

        </v-form>

        <AddAdjustmentFactor 
            :show="showAdjustmentFactorDialog"
            :currentAdjustmentFactors="entityCore.adjustment_factors" 
            @adjustmentFactorAdded="addAdjustmentFactor"
            @closeDialog="closeAdjustmentFactorDialog"
        />
        
        <AddOption 
            :show="showOptionDialog"
            :currentOptions="entityCore.options" 
            @optionAdded="addOption"
            @closeDialog="closeOptionDialog"
        />
    </v-container>
</template>
<script setup>
import LoadingBar from "@/components/interface/LoadingBar.vue";
import FormUpdateFooter from "@/components/interface/FormUpdateFooter.vue";
import adjustmentFactorTypeList from "@/assets/selects/contract-adjustment-factor-type.json";
import { computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import rules from "@/assets/modules/rules.js";
import { useIsCurrentEntityLoaded, useEntityCore, useEntityAncestry, useFetchList, useFetchSingle, useFieldValidation, useToDateObject, useFormatting } from "@/assets/modules/composables.js";
import { useStore } from 'vuex';
import contractTypeList from "@/assets/selects/contract-type.json";
import contractProcurementTypeList from "@/assets/selects/contract-procurement-type.json";
import AddAdjustmentFactor from "@/components/entity/contract/AdjustmentFactorDialog.vue";
import AddOption from "@/components/entity/contract/OptionDialog.vue";

const store = useStore();
const route = useRoute();
const form = ref();
const { isCurrentEntityLoaded } = useIsCurrentEntityLoaded();
const { entityAncestry } = useEntityAncestry();
const { entityCore } = useEntityCore();
const isLoading = reactive({});
const alreadyFetchedData = [];
const { fetchList } = useFetchList(isLoading, alreadyFetchedData);
const { fetchSingle } = useFetchSingle(isLoading, alreadyFetchedData);
const { numbersAndDecimalsOnly, convertToNumber, convertEmptyToNull, convertToDateObject } = useFieldValidation(entityCore);
const { toDateObject} = useToDateObject(entityCore);
const { formatDate } = useFormatting();
const current = {
    id: route.params.entity_id,
    type: "contract"
};
const showAdjustmentFactorDialog = ref(false);
const showOptionDialog = ref(false);

// Convert date to date objects to avoid crashing the date input component
watch(isCurrentEntityLoaded, async () => {
    if(isCurrentEntityLoaded.value){
        toDateObject("start_date");
        toDateObject("end_date");
        toDateObject("award_date");
        toDateObject("final_acceptance_date");
        toDateObject("notice_of_completion_date");
    }
})

// Need the owner document for the contractor list help text
const ownerDoc = computed(() => {
    return store.getters.getEntityDocument(entityAncestry.value.owner);
})
const unitPriceBooksList = computed(() => {
    return store.getters.getChildList(`owner.${entityAncestry.value?.owner}`, "unit_price_book");
})


// The saved contactor meets this criteria and will load once its document has been fetched.
const contractorsList = computed(() => {
    const loading = isLoading['contractor_all'] || isLoading[`contractor_${entityCore.value.contractor_uid}`];
    const disabled = !alreadyFetchedData.includes(`contractor_${entityCore.value.contractor_uid}`);
    const items = store.getters.getContractorList(ownerDoc.value?._id);
    const hint = ownerDoc.value?._id ? `List of contractors for ${ownerDoc.value._name}`:'List of contractors';
    return {
        items: items,
        hint: hint,
        loading: loading,
        disabled: disabled,
    }
})


watch(isCurrentEntityLoaded, async () => {
    if(isCurrentEntityLoaded.value){
        fetchSingle("contractor",entityCore.value.contractor_uid);
        fetchList("unit_price_book",`owner-${entityAncestry.value?.owner}`);
    }
})

function addAdjustmentFactor(adjustmentFactor){
    entityCore.value.adjustment_factors.push(adjustmentFactor);
}
function addOption(option){
    entityCore.value.options.push(option);
}
function getTitleFromValue(value) {
    const item = adjustmentFactorTypeList.find(item => item.value === value);
    return item ? item.title : value;
}
const adjustmentFactorsListHeaders = [
    {
        title: "Value",
        key: "value",
    },
    {
        title: "Type",
        key: "type",
        value: (row) => getTitleFromValue(row.type),
    },
];
const optionsListHeaders = [
    {
        title: "Year",
        key: "year",
    },
    {
        title: "Amount",
        key: "amount",
    },
    {
        title: "Start Date",
        key: "start_date",
        value: (row) => formatDate(row.start_date),
    },
    {
        title: "End Data",
        key: "end_date",
        value: (row) => formatDate(row.end_date),
    },
];
function openAdjustmentFactorDialog(){
    showAdjustmentFactorDialog.value = true;
}
function closeAdjustmentFactorDialog(){
    showAdjustmentFactorDialog.value = false;
}
function openOptionDialog(){
    showOptionDialog.value = true;
}
function closeOptionDialog(){
    showOptionDialog.value = false;
}
</script>