import { Amplify } from "aws-amplify";
import AmplifyPlugin from '@aws-amplify/ui-vue';
import awsconfig from './aws-exports';

import { createApp } from 'vue'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// import validatePermission from './assets/mixins/validatePermission.js'
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'
import * as labsComponents from 'vuetify/labs/components'

// eslint-disable-next-line no-unused-vars
import mitt from 'mitt'

import 'vuetify/styles'
import App from './App.vue'
import router from './lib/router'
import store from './lib/store'

Amplify.configure(awsconfig);

let envColor = '#000000';
if(process.env.ENVIRONMENT === 'prod'){
  envColor = '#305597';
}
const vuetify = createVuetify({
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
        },
    },
    components: {
      ...components,
      ...labsComponents,
    },
    directives,
  theme: {
    defaultTheme: 'light',
    // Here is the URL where I found directions on how to change the theme
    // https://stackoverflow.com/a/75892474
    themes: {
      light: {
        dark: false,
        variables: {}, // ✅ this property is required to avoid Vuetify crash
        colors: {
          primary: envColor,
          secondary: '#2EA3F2'
        },
      }
    }
  }
})


let app = createApp(App)

app.use(vuetify)
    .use(router)
    .use(store)
    .use(AmplifyPlugin)
    .mount('#app')


app.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
  },
})

var m = mitt();
store.commit('SET_EVENTBUS', m);
store.commit('SET_APP', app);
app.provide('app', app)
// eslint-disable-next-line no-unused-vars
app.config.globalProperties.$eventBus = m;

//set the event bus that we can use in the store.
// store.commit('SET_EVENTBUS', m); // Paul told me to delete this.

function hasPermission(requiredPermission) {
  const userPermissions = store.getters.getUserPermissions;
  return userPermissions.includes(requiredPermission) || userPermissions.includes(`${requiredPermission}:any`);
}
app.config.globalProperties.$hasPermission = hasPermission;