<template>
    <v-container fluid>
        <ReadHeader :current="current" />

        <v-row>
            <v-col>
                <KeyValue
                :title="`Title`"
                :value="entityCore.title"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Email`"
                :value="entityCore.email"
                ></KeyValue>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col cols="4">
                <KeyValue
                :title="`Phone`"
                :value="entityCore.phone"
                ></KeyValue>
            </v-col>
            <v-col cols="2">
                <KeyValue
                :title="`Extension`"
                :value="entityCore.phone_extension"
                ></KeyValue>
            </v-col>
            <v-col>
                <KeyValue
                :title="`Mobile`"
                :value="entityCore.mobile"
                ></KeyValue>
            </v-col>
        </v-row>
        
    </v-container>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { useEntityCore } from "@/assets/modules/composables.js";
import ReadHeader from "@/components/interface/ReadHeader.vue";
import KeyValue from "@/components/interface/Key-Value.vue";
const route = useRoute();

const { entityCore } = useEntityCore();

const current = {
    type: "client_contact",
    id: route.params.entity_id,
};
</script>