import validatePermission from '../assets/mixins/validatePermission.js';

import { createWebHashHistory, createRouter } from "vue-router";
import MainPage from "@/components/interface/Main-Page.vue";

import notFound from "@/components/Not-Found.vue";
import fourZeroThree from "@/components/fourZeroThree.vue";



import EntityOwnerList from "@/components/entity/owner/OwnerList.vue";
import EntityOwnerCreate from "@/components/entity/owner/OwnerCreate.vue";
import EntityOwnerRead from "@/components/entity/owner/OwnerRead.vue";
import EntityOwnerUpdate from "@/components/entity/owner/OwnerUpdate.vue";

import EntityProjectCreate from "@/components/entity/project/ProjectCreate.vue";
import EntityProjectRead from "@/components/entity/project/ProjectRead.vue";
import EntityProjectUpdate from "@/components/entity/project/ProjectUpdate.vue";

import EntityWorkOrderCreate from "@/components/entity/work_order/WorkOrderCreate.vue";
import EntityWorkOrderRead from "@/components/entity/work_order/WorkOrderRead.vue";
import EntityWorkOrderUpdate from "@/components/entity/work_order/WorkOrderUpdate.vue";

import EntityProposalCreate from "@/components/entity/proposal/ProposalCreate.vue";
import EntityProposalRead from "@/components/entity/proposal/ProposalRead.vue";
import EntityProposalUpdate from "@/components/entity/proposal/ProposalUpdate.vue";

import EntitySupplemtalWorkOrderCreate from "@/components/entity/supplemental_work_order/SupplementalWorkOrderCreate.vue";
import EntitySupplemtalWorkOrderRead from "@/components/entity/supplemental_work_order/SupplementalWorkOrderRead.vue";
import EntitySupplemtalWorkOrderUpdate from "@/components/entity/supplemental_work_order/SupplementalWorkOrderUpdate.vue";

import EntitySupplemtalProposalCreate from "@/components/entity/supplemental_proposal/SupplementalProposalCreate.vue";
import EntitySupplemtalProposalRead from "@/components/entity/supplemental_proposal/SupplementalProposalRead.vue";
import EntitySupplemtalProposalUpdate from "@/components/entity/supplemental_proposal/SupplementalProposalUpdate.vue";

import EntityClientCreate from "@/components/entity/client/ClientCreate.vue";
import EntityClientRead from "@/components/entity/client/ClientRead.vue";
import EntityClientUpdate from "@/components/entity/client/ClientUpdate.vue";

import EntityOwnerContactCreate from "@/components/entity/owner_contact/OwnerContactCreate.vue";
import EntityOwnerContactRead from "@/components/entity/owner_contact/OwnerContactRead.vue";
import EntityOwnerContactUpdate from "@/components/entity/owner_contact/OwnerContactUpdate.vue";

import EntityLocationCreate from "@/components/entity/location/LocationCreate.vue";
import EntityLocationRead from "@/components/entity/location/LocationRead.vue";
import EntityLocationUpdate from "@/components/entity/location/LocationUpdate.vue";

import EntityUnitPriceBookCreate from "@/components/entity/unit_price_book/UnitPriceBookCreate.vue";
import EntityUnitPriceBookRead from "@/components/entity/unit_price_book/UnitPriceBookRead.vue";
import EntityUnitPriceBookUpdate from "@/components/entity/unit_price_book/UnitPriceBookUpdate.vue";

import EntitySubcontractorCreate from "@/components/entity/subcontractor/SubcontractorCreate.vue";
import EntitySubcontractorRead from "@/components/entity/subcontractor/SubcontractorRead.vue";
import EntitySubcontractorUpdate from "@/components/entity/subcontractor/SubcontractorUpdate.vue";

import EntityContractCreate from "@/components/entity/contract/ContractCreate.vue";
import EntityContractRead from "@/components/entity/contract/ContractRead.vue";
import EntityContractUpdate from "@/components/entity/contract/ContractUpdate.vue";

import EntityClientContactCreate from "@/components/entity/client_contact/ClientContactCreate.vue";
import EntityClientContactRead from "@/components/entity/client_contact/ClientContactRead.vue";
import EntityClientContactUpdate from "@/components/entity/client_contact/ClientContactUpdate.vue";

import EntityContractorsList from "@/components/entity/contractor/ContractorList.vue";
import EntityContractorCreate from "@/components/entity/contractor/ContractorCreate.vue";
import EntityContractorRead from "@/components/entity/contractor/ContractorRead.vue";
import EntityContractorUpdate from "@/components/entity/contractor/ContractorUpdate.vue";

import EntityContractorContactCreate from "@/components/entity/contractor_contact/ContractorContactCreate.vue";
import EntityContractorContactRead from "@/components/entity/contractor_contact/ContractorContactRead.vue";
import EntityContractorContactUpdate from "@/components/entity/contractor_contact/ContractorContactUpdate.vue";

import SignOut from "@/components/interface/SignOut.vue";


const routes = [
  {
    path: "/",
    name: "Home",
    component: MainPage,
    children: [
      {
        path:"/signout",
        name: "Sign Out",
        component: SignOut,
      },
      {
        path:"/owners",
        name: "Owners",
        component: EntityOwnerList,
        meta: {
          pageType: "list",
          entityTypeToFetch: "owner"
        }
      },
      {
        path:"/owner/create",
        name: "EntityOwnerCreate",
        component: EntityOwnerCreate,
        meta: {
          pageType: "create",
          breadcrumbTitle: "Create Owner",
          entityTypeToCreate: "owner"
        }
      },
      {
        path:"/owner/:entity_id",
        name: "EntityOwnerRead",
        component: EntityOwnerRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "owner"
        }
      },
      {
        path:"/owner/:entity_id/update",
        name: "EntityOwnerUpdate",
        component: EntityOwnerUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "owner"
        }
      },
      
      
      {
        path:"/owner/:parent_id/project/create",
        name: "EntityProjectCreate",
        component: EntityProjectCreate,
        meta: {
          pageType: "create",
          entityTypeToCreate: "project",
          breadcrumbTitle: "Create Project",
          parentType: "owner",
        }
      },
      {
        path:"/project/:entity_id",
        name: "EntityProjectRead",
        component: EntityProjectRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "project"
        }
      },
      {
        path:"/project/:entity_id/update",
        name: "EntityProjectUpdate",
        component: EntityProjectUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "project"
        }
      },
      
      
      {
        path:"/project/:parent_id/work_order/create",
        name: "EntityJobOrderCreate",
        component: EntityWorkOrderCreate,
        meta: {
          pageType: "create",
          entityTypeToCreate: "work_order",
          breadcrumbTitle: "Create Job Order",
          parentType: "project",
        }
      },
      {
        path:"/work_order/:entity_id",
        name: "EntityJobOrderRead",
        component: EntityWorkOrderRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "work_order"
        }
      },
      {
        path:"/work_order/:entity_id/update",
        name: "EntityJobOrderUpdate",
        component: EntityWorkOrderUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "work_order"
        }
      },
      
      
      {
        path:"/work_order/:parent_id/proposal/create",
        name: "EntityProposalCreate",
        component: EntityProposalCreate,
        meta: {
          pageType: "create",
          entityTypeToFetch: "work_order",
          entityTypeToCreate: "proposal",
          breadcrumbTitle: "Create Proposal",
          parentType: "work_order",
        }
      },
      {
        path:"/proposal/:entity_id",
        name: "EntityProposalRead",
        component: EntityProposalRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "proposal"
        }
      },
      {
        path:"/proposal/:entity_id/update",
        name: "EntityProposalUpdate",
        component: EntityProposalUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "proposal"
        }
      },
      
      
      {
        path:"/work_order/:parent_id/supplemental_work_order/create",
        name: "EntitySupplemtalWorkOrderCreate",
        component: EntitySupplemtalWorkOrderCreate,
        meta: {
          pageType: "create",
          entityTypeToFetch: "work_order",
          entityTypeToCreate: "supplemental_work_order",
          breadcrumbTitle: "Create Supplemental Job Order",
          parentType: "work_order",
        }
      },
      {
        path:"/supplemental_work_order/:entity_id",
        name: "EntitySupplemtalWorkOrderRead",
        component: EntitySupplemtalWorkOrderRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "supplemental_work_order"
        }
      },
      {
        path:"/supplemental_work_order/:entity_id/update",
        name: "EntitySupplemtalWorkOrderUpdate",
        component: EntitySupplemtalWorkOrderUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "supplemental_work_order"
        }
      },
      
      
      {
        path:"/supplemental_work_order/:parent_id/supplemental_proposal/create",
        name: "EntitySupplemtalProposalCreate",
        component: EntitySupplemtalProposalCreate,
        meta: {
          pageType: "create",
          entityTypeToFetch: "supplemental_work_order",
          entityTypeToCreate: "supplemental_proposal",
          breadcrumbTitle: "Create Supplemental Proposal",
          parentType: "supplemental_work_order",
        }
      },
      {
        path:"/supplemental_proposal/:entity_id",
        name: "EntitySupplemtalProposalRead",
        component: EntitySupplemtalProposalRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "supplemental_proposal"
        }
      },
      {
        path:"/supplemental_proposal/:entity_id/update",
        name: "EntitySupplemtalProposalUpdate",
        component: EntitySupplemtalProposalUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "supplemental_proposal"
        }
      },

      {
        path:"/owner/:parent_id/client/create",
        name: "EntityClientCreate",
        component: EntityClientCreate,
        meta: {
          pageType: "create",
          entityTypeToCreate: "client",
          breadcrumbTitle: "Create Client",
          parentType: "owner",
        }
      },
      {
        path:"/client/:entity_id",
        name: "EntityClientRead",
        component: EntityClientRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "client"
        }
      },
      {
        path:"/client/:entity_id/update",
        name: "EntityClientUpdate",
        component: EntityClientUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "client"
        }
      },
      
      {
        path:"/owner/:parent_id/owner_contact/create",
        name: "EntityOwnerContactCreate",
        component: EntityOwnerContactCreate,
        meta: {
          pageType: "create",
          entityTypeToCreate: "owner_contact",
          breadcrumbTitle: "Create Owner Contact",
          parentType: "owner",
        }
      },
      {
        path:"/owner_contact/:entity_id",
        name: "EntityOwnerContactRead",
        component: EntityOwnerContactRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "owner_contact"
        }
      },
      {
        path:"/owner_contact/:entity_id/update",
        name: "EntityOwnerContactUpdate",
        component: EntityOwnerContactUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "owner_contact"
        }
      },
      
      {
        path:"/owner/:parent_id/location/create",
        name: "EntityLocationCreate",
        component: EntityLocationCreate,
        meta: {
          pageType: "create",
          entityTypeToCreate: "location",
          breadcrumbTitle: "Create Location",
          parentType: "owner",
        }
      },
      {
        path:"/location/:entity_id",
        name: "EntityLocationRead",
        component: EntityLocationRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "location"
        }
      },
      {
        path:"/location/:entity_id/update",
        name: "EntityLocationUpdate",
        component: EntityLocationUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "location"
        }
      },
      
      {
        path:"/owner/:parent_id/unit_price_book/create",
        name: "EntityUnitPriceBookCreate",
        component: EntityUnitPriceBookCreate,
        meta: {
          pageType: "create",
          entityTypeToCreate: "unit_price_book",
          breadcrumbTitle: "Create Unit Price Book",
          parentType: "owner",
        }
      },
      {
        path:"/unit_price_book/:entity_id",
        name: "EntityUnitPriceBookRead",
        component: EntityUnitPriceBookRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "unit_price_book"
        }
      },
      {
        path:"/unit_price_book/:entity_id/update",
        name: "EntityUnitPriceBookUpdate",
        component: EntityUnitPriceBookUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "unit_price_book"
        }
      },
      
      {
        path:"/owner/:parent_id/subcontractor/create",
        name: "EntitySubcontractorCreate",
        component: EntitySubcontractorCreate,
        meta: {
          pageType: "create",
          entityTypeToCreate: "subcontractor",
          breadcrumbTitle: "Create Subcontractor",
          parentType: "owner",
        }
      },
      {
        path:"/subcontractor/:entity_id",
        name: "EntitySubcontractorRead",
        component: EntitySubcontractorRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "subcontractor"
        }
      },
      {
        path:"/subcontractor/:entity_id/update",
        name: "EntitySubcontractorUpdate",
        component: EntitySubcontractorUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "subcontractor"
        }
      },
      
      {
        path:"/owner/:parent_id/contract/create",
        name: "EntityContractCreate",
        component: EntityContractCreate,
        meta: {
          pageType: "create",
          entityTypeToCreate: "contract",
          breadcrumbTitle: "Create Contract",
          parentType: "owner",
        }
      },
      {
        path:"/contract/:entity_id",
        name: "EntityContractRead",
        component: EntityContractRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "contract"
        }
      },
      {
        path:"/contract/:entity_id/update",
        name: "EntityContractUpdate",
        component: EntityContractUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "contract"
        }
      },

      {
        path:"/client/:parent_id/client_contact/create",
        name: "EntityClientContactCreate",
        component: EntityClientContactCreate,
        meta: {
          pageType: "create",
          entityTypeToCreate: "client_contact",
          breadcrumbTitle: "Create Client Contact",
          parentType: "client",
        }
      },
      {
        path:"/client_contact/:entity_id",
        name: "EntityClientContactRead",
        component: EntityClientContactRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "client_contact"
        }
      },
      {
        path:"/client_contact/:entity_id/update",
        name: "EntityClientContactUpdate",
        component: EntityClientContactUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "client_contact"
        }
      },



      {
        path:"/contractors",
        name: "Contractors",
        component: EntityContractorsList,
        meta: {
          pageType: "list",
          entityTypeToFetch: "contractor"
        }
      },
      {
        path:"/contractor/create",
        name: "EntityContractorCreate",
        component: EntityContractorCreate,
        meta: {
          pageType: "create",
          breadcrumbTitle: "Create Contractor",
          entityTypeToCreate: "contractor"
        }
      },
      {
        path:"/contractor/:entity_id",
        name: "EntityContractorRead",
        component: EntityContractorRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "contractor"
        }
      },
      {
        path:"/contractor/:entity_id/update",
        name: "EntityContractorUpdate",
        component: EntityContractorUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "contractor"
        }
      },

      {
        path:"/contractor/:parent_id/contractor_contact/create",
        name: "EntityContractorContactCreate",
        component: EntityContractorContactCreate,
        meta: {
          pageType: "create",
          entityTypeToCreate: "contractor_contact",
          breadcrumbTitle: "Create Contractor Contact",
          parentType: "contractor",
        }
      },
      {
        path:"/contractor_contact/:entity_id",
        name: "EntityContractorContactRead",
        component: EntityContractorContactRead,
        meta: {
          pageType: "read",
          entityTypeToFetch: "contractor_contact"
        }
      },
      {
        path:"/contractor_contact/:entity_id/update",
        name: "EntityContractorContactUpdate",
        component: EntityContractorContactUpdate,
        meta: {
          pageType: "update",
          entityTypeToFetch: "contractor_contact"
        }
      },



      






      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: notFound,
        meta: {
          //requiresAuth: false
        },
      },
    ],
  },
  {
    path: "/403",
    name: "403",
    component: fourZeroThree,
    meta: {
      //requiresAuth: false
    },
  },
];




const router = createRouter({
  history: createWebHashHistory(),
  routes,
});


router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresPermission)) {
    let validated = await validatePermission(to.meta.requiresPermission)
    if (validated){
      next();
    }else{
      next({
        path: "/403",
      });
    }
  } else {
    next();
  }

});



export default router;




// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.name);


  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.name;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.name;
  }
  next()
});



